
export function showToast(message: string, error = false) {
  // Remove any existing toasts
  document.querySelectorAll('.toast-container').forEach(toastContainer => {
    document.body.removeChild(toastContainer);
  });

  // Create a new div element with the .toast-message class
  const toast = document.createElement('div');
  toast.classList.add('toast', 'hide');
  toast.classList.toggle('error', error);
  toast.textContent = message;

  // Create a new div element with the .toast-message-container class
  const toastContainer = document.createElement('div');
  toastContainer.classList.add('toast-container');
  toastContainer.appendChild(toast);

  // Append the toast to the body
  document.body.appendChild(toastContainer);

  // Show the toast by removing the .hide class
  setTimeout(() => {
    toast.classList.remove('hide');
  }, 100);

  // Hide toast by first reversing the animation, then removing the element from the DOM
  const hideToast = () => {
    clearTimeout(hideTimeout);
    toast.classList.add('hide');
    // Wait for the animation to finish before removing the element from the DOM
    toast.ontransitionend = () => {
      document.body.removeChild(toastContainer);
    }
  }

  // Hide the toast automatically after 5 seconds
  const hideTimeout = setTimeout(hideToast, 5000);

  // Add a click event listener to the toast that hides the toast message
  toast.onclick = () => {
    hideToast();
  }

  // Return a method for hiding the toast
  return () => {
    hideToast();
  };
}
