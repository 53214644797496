import { initializeApp, getApp } from 'firebase/app';
import { httpsCallable, getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// Import showToast function from toast-message.ts
import { showToast } from './toast';

const validateInput = (input: HTMLInputElement | HTMLTextAreaElement) => {
  let isValid = true;

  // Mark required empty inputs as invalid
  if (input.hasAttribute('required')) {
    const isInvalid = !input.value;
    input.classList.toggle('invalid', isInvalid);
    const formGroup = input.closest('.form-group') as HTMLElement;
    formGroup.setAttribute('data-error', isInvalid ? 'This field is required' : '');
    isValid = isValid && !isInvalid;
  }

  // Check if tel fields have correct format
  if (input.type === 'tel') {
    const isInvalid = !input.value.match(/^\+?\d[\d\s]*$/);
    input.classList.toggle('invalid', isInvalid);
    const formGroup = input.closest('.form-group') as HTMLElement;
    formGroup.setAttribute('data-error', isInvalid ? 'Please enter a valid phone number' : '');
    isValid = isValid && !isInvalid;
  }

  // Check if email fields have correct format
  if (input.type === 'email') {
    const isInvalid = !input.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    input.classList.toggle('invalid', isInvalid);
    const formGroup = input.closest('.form-group') as HTMLElement;
    formGroup.setAttribute('data-error', isInvalid ? 'Please enter a valid email address' : '');
    isValid = isValid && !isInvalid;
  }

  return isValid;
}

// Wait for the DOM to be ready
document.addEventListener('DOMContentLoaded', function(event) {
  const contactForm = document.getElementById('contact-form') as HTMLFormElement;
  if (!contactForm) {
    console.error('Contact form not found');
    return;
  }

  const inputs = contactForm.querySelectorAll('input, textarea') as NodeListOf<HTMLInputElement | HTMLTextAreaElement>;

  for (const input of inputs) {
    input.addEventListener('input', (event) => {
      validateInput(input);
    });
  }

  const resetForm = () => {
    for (const input of inputs) {
      input.value = '';
      input.classList.remove('invalid');
      const formGroup = input.closest('.form-group') as HTMLElement;
      formGroup.removeAttribute('data-error');
    }
    // window.history.replaceState({}, document.title, window.location.pathname);
  }

  contactForm.addEventListener('submit', (event) => {
    event.preventDefault();

    let isValid = true;
    for (const input of inputs) {
      isValid = validateInput(input) && isValid;
    }
    if (!isValid) {
      return;
    }

    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    const name = formData.get('name') as string;
    const email = formData.get('email') as string;
    const phone = formData.get('phone') as string;
    const message = formData.get('message') as string;

    console.log('Sending email:', { name, email, phone, message });

    const app = initializeApp({ 
      apiKey: "AIzaSyBRin61aINMxtcFCqxdmHDWB-mBLQGpgyo",
      appId: "1:216760873083:web:f9a726018ae15eb27f72de",
      measurementId: "G-37QK73T1CK",
      projectId: "cando-consulting",
    });
    const functions = getFunctions(app);
    // Connect your app to the Cloud Functions Emulator running on localhost for local testing.
    // When running locally, Cloud Functions will be available at http://localhost:5001/<projectId>/<region>/<functionName>
    // Learn more about testing Cloud Functions locally: https://firebase.google.com/docs/emulator-suite/connect_functions
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
      connectFunctionsEmulator(functions, '127.0.0.1', 5001);
    }
    const sendEmail = httpsCallable(functions, 'sendEmail');
    
    sendEmail({ name, email, phone, message }).then((result) => {
      resetForm();
      showToast('Message sent successfully!');
    }).catch((error) => {
      showToast(`Error sending message: ${error.message}`, true);
    });
  });
});